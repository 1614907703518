var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title: _vm.appName + " - Event Updated: " + _vm.event.title
              }
            }),
            _c(
              "gov-back-link",
              {
                attrs: {
                  to: { name: "events-show", params: { event: _vm.event.id } }
                }
              },
              [_vm._v("Back to event")]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "one-half" } },
                      [
                        _c("gov-heading", { attrs: { size: "xl" } }, [
                          _vm._v("Update request submitted")
                        ]),
                        _c("gov-body", [
                          _vm._v(
                            "\n            Your update request for this event has been received. It will need\n            to be approved by an admin before the changes will be applied.\n          "
                          )
                        ]),
                        _c(
                          "gov-button",
                          {
                            attrs: {
                              to: {
                                name: "events-show",
                                params: { event: this.$route.params.event }
                              }
                            }
                          },
                          [_vm._v("Back to event")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }